<template>
  <footer class="footer">
    <div class="footer-content"
    data-aos-delay="200"
    data-aos="fade-up"
    data-aos-duration="1600"
    >
      <agent-button/>
     
        <ul class="footer-content__list">
          <li
          class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn1" :key="index">
          <router-link :to="route.route">{{ route.name }}</router-link>
          </li>
        </ul>
        <ul class="footer-content__list">
          <li
          class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn2" :key="index">
            <router-link :to="route.route">{{ route.name }}</router-link>
          </li>
        </ul>
        <ul class="footer-content__list">
          <li
          class="footer-content__list--item" @click="isActive = !isActive" v-for="(route, index) in routesColumn3" :key="index" >
            <a :href="route.route">{{ route.tel }}</a>
          </li>
        </ul>
      </div>
    <div class="footer-content__rights">
      <div class="apk-link">
        <div class="container-img-text">
          <img src="../assets/images/certified.png" alt="">
          <router-link to="apk">{{ $t("APP") }}</router-link>
        </div>
      </div>
      <p>{{ $t("Rights") }}</p>
    </div>
    <mobile-menu/>
  </footer>
</template>


<script>
  import AgentButton from "@/components/agent-button.vue"
  import MobileMenu from '@/components/mobile-menu.vue'
  import { useI18n } from 'vue-i18n';
  import { reactive, watch } from 'vue';
  
  export default {
    name: "FooterComponent",
    components: {
      AgentButton,
      MobileMenu
    },
    setup() {
      const { t , locale }  = useI18n();


      const routesColumn1= reactive( [
          {
            name       :  t("footerColum1.0.name"),
            route      :  t("footerColum1.0.route")
          },
          {
            name       :   t("footerColum1.1.name"),
            route      :   t("footerColum1.1.route")
          },
          {
            name       :   t("footerColum1.2.name"),
            route      :   t("footerColum1.2.route")
          },
          {
            name       :   t("footerColum1.3.name"),
            route      :   t("footerColum1.3.route")
          }
        ])

        const routesColumn2 = reactive([
          {
            name       :  t("footerColum2.0.name"),
            route      :  t("footerColum2.0.route")
          },
          {
            name       :  t("footerColum2.1.name"),
            route      :  t("footerColum2.1.route")
          },
          {
            name       :  t("footerColum2.2.name"),
            route      :  t("footerColum2.2.route")
          },
          {
            name       :   t("footerColum2.3.name"),
            route      :   t("footerColum2.3.route")
          }
        ])

        const routesColumn3 = [
          {
            tel       :  '1-866-895-4647',
            route      :  'tel:+1-866-895-4647'
          }
        ]

             // Observar los cambios de idioma y actualiza los slides
      const updateSlides = () => {
        routesColumn1.forEach((opcion, index) => {
          opcion.name = t(`footerColum1.${index}.name`);
          opcion.route = t(`footerColum1.${index}.route`);
        });
        routesColumn2.forEach((opcion, index) => {
          opcion.name = t(`footerColum2.${index}.name`);
          opcion.route = t(`footerColum2.${index}.route`);
        });
      };

      // Escuchar los cambios de idioma y actualiza los slides
      watch(locale, () => {
        updateSlides();
      });
      return {
        routesColumn1,
        routesColumn2,
        routesColumn3
      }
      
    },
  };
</script>
