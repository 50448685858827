import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/sportsbook',
    name: 'sportsbook-langen',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/apk',
    name: 'apk',
    component: () => import('../pages/apk.vue'),  
  },
  {
    path: '/apuestas-deportivas',
    name: 'sportsbook-langes',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/reset-pass',
    name: 'reset-pass-langen',
    component: () => import('../pages/reset-pass.vue'),  
  },
  {
    path: '/restablecer-contrasena',
    name: 'reset-pass-langes',
    component: () => import('../pages/reset-pass.vue'),  
  },
  {
    path: '/casino',
    name: 'casino-langen',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/casino-es',
    name: 'casino-langes',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/racebook',
    name: 'racebook-langen',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/caballos',
    name: 'racebook-langes',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/live-betting',
    name: 'live-betting-langen',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/apuestas-en-vivo',
    name: 'live-betting-langes',
    component: () => import('../pages/live-betting.vue'),  
  },
  {
    path: '/house-rules',
    name: 'house-rules-langen',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/reglas-de-la-casa',
    name: 'house-rules-langes',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/sports-rules',
    name: 'sports-rules-langen',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/reglas-deportivas',
    name: 'sports-rules-langes',
    component: () => import('../pages/sports-rules.vue'),  
  },
  {
    path: '/props-builder',
    name: 'props-builder-langen',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/crea-tus-props',
    name: 'props-builder-langes',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/horse-rules',
    name: 'horse-rules-langen',
    component: () => import('../pages/horse-rules.vue'),  
  },
  {
    path: '/reglas-de-caballos',
    name: 'horse-rules-langes',
    component: () => import('../pages/horse-rules.vue'),  
  },
  {
    path: '/responsible-gaming',
    name: 'responsible-gaming-langen',
    component: () => import('../pages/responsible-gaming.vue'),  
  },
  {
    path: '/juego-responsable',
    name: 'responsible-gaming-langes',
    component: () => import('../pages/responsible-gaming.vue'),  
  },
  {
    path: '/reglas-de-parlay-del-mismo-equipo',
    name: 'same-game-parlay-rules-langes',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    name: 'same-game-parlay-rules-langpt',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
});

export default router